import { inject } from '@angular/core';
import { HttpInterceptorFn } from '@angular/common/http';
import { UserDetailService } from '../../services/user-detail/user-detail.service';

export const authenticationInterceptor: HttpInterceptorFn = (req, next) => {
  let userDetail: any = null;
  inject(UserDetailService).currentMessage.subscribe(data => userDetail = data);
  if(userDetail && userDetail.token){
    const authReq = req.clone({setHeaders: {Authorization: `Bearer ${userDetail.token}`}});
    return next(authReq);
  }
  return next(req);
};
