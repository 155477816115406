import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { protectedResource } from '../../auth/protected-resource';
import { KriyababajiService } from '../../services/kriyababaji/kriyababaji.service';
import { SnackBarService, snackBarClass } from '../../services/snack-bar/snack-bar.service';
import { UserDetailService } from '../../services/user-detail/user-detail.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, FormsModule, ReactiveFormsModule, MatDialogModule, MatButtonModule, MatIconModule, MatFormFieldModule, MatInputModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit, OnDestroy{
  loginForm = new FormGroup({
    userEmail: new FormControl(null, [Validators.required, Validators.email]),
    userPassword: new FormControl(null, [Validators.required, Validators.minLength(6)])
  });
  passwordStatus: boolean = true;
  submitStatus: boolean = false;
  loginSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<LoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private kriyababajiService: KriyababajiService,
    private snackBarService: SnackBarService,
    private userDetailService: UserDetailService
  ){}

  ngOnInit(): void {
    //console.log(this.data);
  }

  get f():{[key: string]: AbstractControl}{
    return this.loginForm.controls;
  }

  submit(){
    if(this.loginForm.valid){
      this.submitStatus = true;
      let url: string = `${environment.apiURL}${protectedResource.userLogin.resourceURL}`;
      this.loginSubscription = this.kriyababajiService.sendData(url, this.loginForm.value).subscribe({
        next: (response) => {
          this.userDetailService.changeMessage(response.value);
          localStorage.setItem('userDetail', JSON.stringify(response.value));
          this.snackBarService.showSnackBar(response.message, snackBarClass.Success);
          this.closePopup();
          //console.log(response);
        },
        error: (err) => {
          this.snackBarService.showSnackBar(err && err.error && err.error.message ? err.error.message : err.message, snackBarClass.Error);
          //console.log(err);
        }
      });
    }
  }

  closePopup(data?: string){
    this.dialogRef.close(data);
  }

  ngOnDestroy(): void {
    this.loginSubscription ? this.loginSubscription.unsubscribe() : null;
  }
}
