import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatBottomSheetModule, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-update',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatBottomSheetModule],
  templateUrl: './update.component.html',
  styleUrl: './update.component.scss'
})
export class UpdateComponent {
  constructor(
    private bottomSheetRef: MatBottomSheetRef<UpdateComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: string
    ){}

  close(): void{
    this.bottomSheetRef.dismiss();
  }
}
