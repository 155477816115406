<section class="headerInfo">
    <div class="container">
        <div class="row">
            <div class="col-md-6 display-none">
                <ul>
                    <li>
                        <mat-icon fontIcon="self_improvement"></mat-icon>
                        <p>Welcome to Kriya Babaji Yoga Sangam</p>
                    </li>
                </ul>
            </div>
            <div class="col-md-6">
                <ul>
                    <li class="display-none">
                        <mat-icon fontIcon="smartphone"></mat-icon>
                        <p><a href="tel:+91 9444054279">+91 9444054279</a></p>
                    </li>
                    <li class="display-none">
                        <mat-icon fontIcon="mail"></mat-icon>
                        <p><a href="mailto:info@kriyababajiyogasangam.org">info&#64;kriyababajiyogasangam.org</a></p>
                    </li>
                    <li>
                        @if(userDetail){
                            <button mat-button [matMenuTriggerFor]="userInfo">Hi {{userDetail.userName}}</button>
                            <mat-menu class="profileDropdown" #userInfo="matMenu">
                                @if(userDetail.userRole.includes('Admin')){
                                    <button mat-menu-item (click)="setNavigation()"><mat-icon fontIcon="person"></mat-icon><span>Admin</span></button>
                                }
                                <button mat-menu-item (click)="logout('button')"><mat-icon fontIcon="logout"></mat-icon><span>Logout</span></button>
                            </mat-menu>
                        }
                        @else{
                            <button mat-button (click)="openLoginPopup()">Login / Register</button>
                        }
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>

<section [class]="getScrollPosition > 100 ? 'header headerFixed' : 'header'">
    <div class="container">
        <div class="headerContent">
            <div class="logo">
                <img src="assets/images/icon.png" alt="logo" />
                <h4>OM KRIYA BABAJI NAMA AUM</h4>
            </div>
            <app-menu />
        </div>
    </div>
</section>

<div class="scrollTop" *ngIf="getScrollPosition > 100" (click)="scrollTop()">
    <mat-icon fontIcon="keyboard_arrow_up"></mat-icon>
</div>