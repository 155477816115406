<div class="menu">
    <div class="menuIcon">
        <button mat-icon-button color="primary" (click)="mobileMenuNavStatus = !mobileMenuNavStatus"><mat-icon fontIcon="{{mobileMenuNavStatus ? 'menu_open' : 'menu'}}"></mat-icon></button>
    </div>
    <ul [class]="mobileMenuNavStatus ? 'mobileMenu' : ''">
        <li [class]="menu.active ? 'active' : ''" *ngFor="let menu of menuList; let i = index">
            <span class="navDisable" *ngIf="menu.subMenu.length"></span>
            <a routerLink="{{menu.link}}" (click)="setMenuActive(i+1)">{{menu.name}} <mat-icon fontIcon="expand_more" *ngIf="menu.subMenu.length"></mat-icon></a>
            <ul class="subMenu" *ngIf="menu.subMenu.length">
                <li [class]="subMenu.active ? 'subMenuActive' : ''" *ngFor="let subMenu of menu.subMenu; let subMenuIndex = index">
                    <span class="navDisable" *ngIf="subMenu.subMenu2.length"></span>
                    <a routerLink="{{subMenu.link}}" (click)="setMenuActive(i+1, subMenuIndex+1)">{{subMenu.name}} <mat-icon fontIcon="chevron_right" *ngIf="subMenu.subMenu2.length"></mat-icon></a>
                    <ul class="subMenu2" *ngIf="subMenu.subMenu2.length">
                        <li [class]="subMenu2.active ? 'subMenu2Active' : ''" *ngFor="let subMenu2 of subMenu.subMenu2; let subMenu2Index = index">
                            <a routerLink="{{subMenu2.link}}" (click)="setMenuActive(i+1, subMenuIndex+1, subMenu2Index+1)">{{subMenu2.name}}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
        <!-- <li>
            <a href="#">Home</a>
        </li>
        <li>
            <a href="#">About Us</a>
            <ul class="subMenu">
                <li>
                    <a href="#">Company</a>
                </li>
            </ul>
        </li>
        <li class="active">
            <a href="#">Menu 1 <mat-icon fontIcon="expand_more"></mat-icon></a>
            <ul class="subMenu">
                <li class="subMenuActive">
                    <a href="#">Menu 2 <mat-icon fontIcon="chevron_right"></mat-icon></a>
                    <ul class="subMenu2">
                        <li class="subMenu2Active">
                            <a href="#">Menu 21</a>
                        </li>
                        <li>
                            <a href="#">Menu 22</a>
                        </li>
                        <li>
                            <a href="#">Menu 23</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li> -->
    </ul>
</div>