import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

interface ImenuList{
  id: number
  name: string
  link: string
  active: boolean
  subMenu: IsubMenu[]
}
interface IsubMenu{
  id: number
  name: string
  link: string
  active: boolean
  subMenu2: IsubMenu2[]
}
interface IsubMenu2{
  id: number
  name: string
  link: string
  active: boolean
}

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [CommonModule, RouterLink, MatIconModule, MatButtonModule],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent implements OnInit{
  mobileMenuNavStatus: boolean = false;
  menuList: ImenuList[] = [
    {id: 1, name: 'Home', link: '/home', active: true, subMenu: []},
    {id: 2, name: 'About Us', link: '#', active: false, subMenu: [
      {id: 21, name: 'Centres', link: '/centres', active: false, subMenu2: []},
      {id: 22, name: 'Mission', link: '#', active: false, subMenu2: []},
      {id: 23, name: 'Gallery', link: '/gallery', active: false, subMenu2: []},
      {id: 24, name: 'Events', link: '#', active: false, subMenu2: []},
      {id: 25, name: 'Contact', link: '/contact', active: false, subMenu2: []},
    ]},
    {id: 3, name: 'Kriya Yoga', link: '/kriya-yoga', active: false, subMenu: []},
    {id: 4, name: 'Kriya Babaji', link: '/kriya-babaji', active: false, subMenu: []},
    {id: 5, name: 'Guru Tradition', link: '/guru-tradition', active: false, subMenu: []},
    {id: 6, name: 'Yogi S.A.A.Ramaiah', link: '#', active: false, subMenu: [
      {id: 61, name: 'Life of Yogiar', link: '/yogi-saa-ramaiah/life-of-yogiar', active: false, subMenu2: []},
      {id: 62, name: 'Resume', link: '/yogi-saa-ramaiah/resume', active: false, subMenu2: []},
      {id: 63, name: 'Education', link: '/yogi-saa-ramaiah/education', active: false, subMenu2: []},
      {id: 64, name: 'Further Education', link: '/yogi-saa-ramaiah/further-education', active: false, subMenu2: []},
      {id: 65, name: 'International Congresses', link: '/yogi-saa-ramaiah/international-congresses', active: false, subMenu2: []},
      {id: 66, name: 'Research', link: '/yogi-saa-ramaiah/research', active: false, subMenu2: []},
      {id: 67, name: 'Experience', link: '/yogi-saa-ramaiah/experience', active: false, subMenu2: []}
    ]},
    {id: 8, name: 'E-Books', link: '/ebooks', active: false, subMenu: []}
  ];
  // menuList: ImenuList[] = [
  //   {id: 1, name: 'Home', link: '#', active: true, subMenu: []},
  //   {id: 2, name: 'About Us', link: '#', active: false, subMenu: [
  //     {id: 21, name: 'Company', link: '#', active: false, subMenu2: []},
  //     {id: 22, name: 'Events', link: '#', active: false, subMenu2: []},
  //     {id: 23, name: 'Gallery', link: '#', active: false, subMenu2: []},
  //   ]},
  //   {id: 3, name: 'Services', link: 'dsds', active: false, subMenu: [
  //     {id: 31, name: 'Service 1', link: '#', active: false, subMenu2: [
  //       {id: 311, name: 'Service 11', link: '#', active: false},
  //       {id: 312, name: 'Service 12', link: '#', active: false},
  //       {id: 313, name: 'Service 13', link: '#', active: false}
  //     ]},
  //     {id: 32, name: 'Service 2', link: '#', active: false, subMenu2: [
  //       {id: 321, name: 'Service 21', link: '#', active: false},
  //       {id: 322, name: 'Service 22', link: '#', active: false},
  //       {id: 323, name: 'Service 23', link: '#', active: false}
  //     ]},
  //     {id: 33, name: 'Service 3', link: '#', active: false, subMenu2: []},
  //   ]},
  //   {id: 4, name: 'Testimonial', link: '#', active: false, subMenu: []},
  //   {id: 5, name: 'Location', link: '#', active: false, subMenu: []},
  //   {id: 6, name: 'Contact Us', link: '#', active: false, subMenu: []}
  // ];

  ngOnInit(): void {
    this.menuActive();
  }

  menuActive(){
    let getURL: string = window.location.pathname;
    if(getURL !== '/'){
      this.menuList.map(val => {
        val.active = val.link === getURL ? true : false;
        if(val.subMenu && val.subMenu.length > 0){
          val.subMenu.map(subMenu => {
            subMenu.link === getURL ? (subMenu.active = true, val.active = true) : null;
            if(subMenu.subMenu2 && subMenu.subMenu2.length > 0){
              subMenu.subMenu2.map(subMenu2 => {
                subMenu2.link === getURL ? (subMenu2.active = true, subMenu.active = true, val.active = true) : null;
              });
            }
          });
        }
      });
    }
  }

  setMenuActive(index: number, subMenuIndex?: number, subMenu2Index?: number){
    this.menuList.map(val => (val.active = false, val.subMenu.map(subMenu => (subMenu.active = false, subMenu.subMenu2.map(subMenu2 => subMenu2.active = false)))));
    index ? this.menuList[index-1].active = true : null;
    subMenuIndex ? this.menuList[index-1].subMenu[subMenuIndex-1].active = true : null;
    subMenuIndex && subMenu2Index ? this.menuList[index-1].subMenu[subMenuIndex-1].subMenu2[subMenu2Index-1].active = true : null;
    this.mobileMenuNavStatus = false;
    document.documentElement.scrollTop = 0;
  }
}
