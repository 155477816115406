import { Routes } from '@angular/router';
import { adminGuard } from './auth/guard/admin/admin.guard'

let title: string = '| Kriya Babaji Yoga Sangam';
export const routes: Routes = [
    {
        path: 'admin',
        loadComponent: () => import('./admin/admin.component').then(c => c.AdminComponent),
        title: `Admin ${title}`,
        canActivate: [adminGuard]
    },
    {
        path: 'home',
        loadComponent: () => import('./home/home.component').then(c => c.HomeComponent),
        title: `Home ${title}`
    },
    {
        path: 'ebooks',
        loadComponent: () => import('./ebooks/ebooks.component').then(c => c.EbooksComponent),
        title: `Ebooks ${title}`
    },
    {
        path: 'kriya-yoga',
        loadComponent: () => import('./kriya-yoga/kriya-yoga.component').then(c => c.KriyaYogaComponent),
        title: `Kriya Yoga ${title}`
    },
    {
        path: 'kriya-babaji',
        loadComponent: () => import('./kriya-babaji/kriya-babaji.component').then(c => c.KriyaBabajiComponent),
        title: `Kriya Babaji ${title}`
    },
    {
        path: 'guru-tradition',
        loadComponent: () => import('./guru-tradition/guru-tradition.component').then(c => c.GuruTraditionComponent),
        title: `Guru Tradition ${title}`
    },
    {
        path: 'yogi-saa-ramaiah/life-of-yogiar',
        loadComponent: () => import('./yogi-saa-ramaiah/yogi-saa-ramaiah.component').then(c => c.YogiSaaRamaiahComponent),
        title: `Life of Yogi S.A.A.Ramaiah ${title}`
    },
    {
        path: 'yogi-saa-ramaiah/resume',
        loadComponent: () => import('./yogi-saa-ramaiah/resume/resume.component').then(c => c.ResumeComponent),
        title: `Resume | Yogi S.A.A.Ramaiah ${title}`
    },
    {
        path: 'yogi-saa-ramaiah/education',
        loadComponent: () => import('./yogi-saa-ramaiah/education/education.component').then(c => c.EducationComponent),
        title: `Education | Yogi S.A.A.Ramaiah ${title}`
    },
    {
        path: 'yogi-saa-ramaiah/further-education',
        loadComponent: () => import('./yogi-saa-ramaiah/further-education/further-education.component').then(c => c.FurtherEducationComponent),
        title: `Further Education | Yogi S.A.A.Ramaiah ${title}`
    },
    {
        path: 'yogi-saa-ramaiah/international-congresses',
        loadComponent: () => import('./yogi-saa-ramaiah/international-congresses/international-congresses.component').then(c => c.InternationalCongressesComponent),
        title: `International Congresses | Yogi S.A.A.Ramaiah ${title}`
    },
    {
        path: 'yogi-saa-ramaiah/research',
        loadComponent: () => import('./yogi-saa-ramaiah/research/research.component').then(c => c.ResearchComponent),
        title: `Research | Yogi S.A.A.Ramaiah ${title}`
    },
    {
        path: 'yogi-saa-ramaiah/experience',
        loadComponent: () => import('./yogi-saa-ramaiah/experience/experience.component').then(c => c.ExperienceComponent),
        title: `Experience | Yogi S.A.A.Ramaiah ${title}`
    },
    {
        path: 'centres',
        loadComponent: () => import('./centres/centres.component').then(c => c.CentresComponent),
        title: `Centres ${title}`
    },
    {
        path: 'gallery',
        loadComponent: () => import('./gallery/gallery.component').then(c => c.GalleryComponent),
        title: `Gallery ${title}`
    },
    {
        path: 'contact',
        loadComponent: () => import('./contact/contact.component').then(c => c.ContactComponent),
        title: `Contact ${title}`
    },
    {
        path: 'siddhas',
        loadComponent: () => import('./pages/siddhas/siddhas.component').then(c => c.SiddhasComponent),
        title: `Siddhas ${title}`
    },
    {
        path: 'siva-yoga-siddhantam',
        loadComponent: () => import('./pages/siva-yoga-siddhantam/siva-yoga-siddhantam.component').then(c => c.SivaYogaSiddhantamComponent),
        title: `Siva Yoga Siddhantam ${title}`
    },
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: 'home'
    }
];
