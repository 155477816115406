import { Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AppInstallComponent } from '../app-install/app-install.component';

interface IfooterLink{
  id: number
  name: string
  link: string
}

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, RouterLink, MatButtonModule, MatIconModule, AppInstallComponent],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  usefulLink: IfooterLink[] = [
    {id: 1, name: 'Home', link: '/home'},
    {id: 2, name: 'Kriya Yoga', link: '/kriya-yoga'},
    {id: 3, name: 'Kriya Babaji', link: '/kriya-babaji'},
    {id: 4, name: 'Guru Tradition', link: '/guru-tradition'},
    {id: 5, name: 'Yogi S.A.A.Ramaiah', link: '/yogi-saa-ramaiah/life-of-yogiar'},
    {id: 6, name: 'E-Books', link: '/ebooks'}
  ];
  aboutUsLink: IfooterLink[] = [
    {id: 1, name: 'Centres', link: '/centres'},
    {id: 2, name: 'Mission', link: '#'},
    {id: 3, name: 'Gallery', link: '/gallery'},
    {id: 4, name: 'Events', link: '#'},
    {id: 5, name: 'Contact', link: '/contact'},
    {id: 6, name: 'Kriya Yoga Calendar App', link: 'https://play.google.com/store/apps/details?id=navil.kriyayogacalendar'},
  ];
  getYear: number = new Date().getFullYear();

  openLink(data: string){
    window.open(data, '_blank');
  }

  scrollTop(){
    document.documentElement.scrollTop = 0;
  }
}
