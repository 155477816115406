<footer>
    <div class="footersection">
        <div class="container">
            <div class="row">
                <div class="col-md-5">
                    <h4>About Us</h4>
                    <p>Babaji the climax of Eighteen Tamil Yoga Siddhas, felt the need for a society in his name through which he could contact his KriyaYoga devotees the world over. Kriya Babaji Yoga Sangam came into existence on 1952 October 17th Friday afternoon between 12 noon and 3 pm, the sacred day of Deepavali,and Yogiar (Prof.Dr.Yogi.S.A.A.Ramaiah, the direct disciple of Sathguru Kriya Babaji continued to spead the science of Kriya Yoga to the nooks and corners of the earth, giving initiation and training to sincere seekers of Truth.</p>
                    <button mat-icon-button color="primary" (click)="openLink('https://www.youtube.com/@kriyababajiyogasangam1729')"><mat-icon fontIcon="youtube_activity"></mat-icon></button>
                </div>
                <div class="col-md-2">
                    <h4>Useful Link</h4>
                    <ul class="link">
                        <li *ngFor="let item of usefulLink"><a routerLink="{{item.link}}" (click)="scrollTop()"><img ngSrc="assets/images/om.png" width="17" height="17" priority alt="" /><p>{{item.name}}</p></a></li>
                    </ul>
                </div>
                <div class="col-md-2">
                    <h4>About Us</h4>
                    <ul class="link">
                        <li *ngFor="let item of aboutUsLink">
                            <ng-container *ngIf="item.id === 6">
                                <a href="{{item.link}}" target="_blank"><img ngSrc="assets/images/om.png" width="17" height="17" priority alt="" /><p>{{item.name}}</p></a>
                            </ng-container>
                            <ng-container *ngIf="item.id !== 6">
                                <a routerLink="{{item.link}}" (click)="scrollTop()"><img ngSrc="assets/images/om.png" width="17" height="17" priority alt="" /><p>{{item.name}}</p></a>
                            </ng-container>
                        </li>
                    </ul>
                </div>
                <div class="col-md-3">
                    <h4>Contact Information</h4>
                    <ul class="contact">
                        <li>
                            <div class="icon"><mat-icon fontIcon="smartphone"></mat-icon></div>
                            <div class="info">
                                <p>Call us</p>
                                <h6><a href="tel:+91 9444054279">+91 9444054279</a></h6>
                            </div>
                        </li>
                        <li>
                            <div class="icon"><mat-icon fontIcon="mail"></mat-icon></div>
                            <div class="info">
                                <p>Email us</p>
                                <h6><a href="mailto:info@kriyababajiyogasangam.org">info&#64;kriyababajiyogasangam.org</a></h6>
                            </div>
                        </li>
                        <li>
                            <div class="icon"><mat-icon fontIcon="location_on"></mat-icon></div>
                            <div class="info">
                                <p>Reach us</p>
                                <h6><a href="#">No.13, AR. Street, Kanadukathan P.O., P.T.T. Dist., Tamil Nadu-823-103, South India.</a></h6>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright">
        <div class="container">
            <p>Copyright &#169; {{getYear}} by Kriya Babaji Yoga Sangam. All Right Reserved.</p>
        </div>
    </div>
    <app-app-install />
</footer>