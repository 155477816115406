import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SelectComponent, IreceiveSelect } from '../select/select.component';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { protectedResource } from '../../auth/protected-resource';
import { KriyababajiService } from '../../services/kriyababaji/kriyababaji.service';
import { SnackBarService, snackBarClass } from '../../services/snack-bar/snack-bar.service';
import { Icountry, Istate, Icity } from '../../services/interface/interface.service';

@Component({
  selector: 'app-registration',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, FormsModule, ReactiveFormsModule, MatDialogModule, MatButtonModule, MatIconModule, MatFormFieldModule, MatInputModule, MatProgressSpinnerModule, SelectComponent],
  templateUrl: './registration.component.html',
  styleUrl: './registration.component.scss'
})
export class RegistrationComponent implements OnInit, OnDestroy{
  formType: 'R' | 'V' = 'R';
  userRegistrationForm = new FormGroup({
    userName: new FormControl<string | null>(null, [Validators.required, Validators.minLength(4)]),
    userEmail: new FormControl<string | null>(null, [Validators.required, Validators.email]),
    userPassword: new FormControl<string | null>(null, [Validators.required, Validators.minLength(6)]),
    confirmPassword: new FormControl<string | null>(null, [Validators.required]),
    userCountry: new FormControl<Icountry | null>(null, [Validators.required]),
    userState: new FormControl<Istate | null>(null, [Validators.required]),
    userCity: new FormControl<Icity | null>(null, [Validators.required])
  },
  {
    validators: this.matchValidator('userPassword', 'confirmPassword')
  });
  userVerficationForm = new FormGroup({
    //verificationCode: new FormControl<string | null>(null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
    opt1: new FormControl<string | null>(null, [Validators.required, Validators.minLength(1), Validators.maxLength(1), Validators.pattern(/^[0-9]\d*$/)]),
    opt2: new FormControl<string | null>(null, [Validators.required, Validators.minLength(1), Validators.maxLength(1), Validators.pattern(/^[0-9]\d*$/)]),
    opt3: new FormControl<string | null>(null, [Validators.required, Validators.minLength(1), Validators.maxLength(1), Validators.pattern(/^[0-9]\d*$/)]),
    opt4: new FormControl<string | null>(null, [Validators.required, Validators.minLength(1), Validators.maxLength(1), Validators.pattern(/^[0-9]\d*$/)]),
    opt5: new FormControl<string | null>(null, [Validators.required, Validators.minLength(1), Validators.maxLength(1), Validators.pattern(/^[0-9]\d*$/)]),
    opt6: new FormControl<string | null>(null, [Validators.required, Validators.minLength(1), Validators.maxLength(1), Validators.pattern(/^[0-9]\d*$/)])
  });
  passwordStatus: boolean = true;
  confirmPasswordStatus: boolean = true;
  countryList: Icountry[] = [];
  countryStatus: boolean = false;
  stateList: Istate[] = [];
  stateStatus: boolean = false;
  cityList: Istate[] = [];
  cityStatus: boolean = false;
  submitStatus: boolean = false;
  userRegistrationSubscription: Subscription;
  userVerficationSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<RegistrationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private kriyababajiService: KriyababajiService,
    private snackBarService: SnackBarService
  ){}

  ngOnInit(): void {
    //console.log(this.data);
    this.getCountryList();
  }

  get f():{[key: string]: AbstractControl}{
    return this.userRegistrationForm.controls;
  }
  get v():{[key: string]: AbstractControl}{
    return this.userVerficationForm.controls;
  }

  submit(){
    if(this.formType === 'R' && this.userRegistrationForm.valid){
      this.userRegistration();
    }
    else if(this.formType === 'V' && this.userVerficationForm.valid){
      this.userVerfication();
    }
    console.log(this.userRegistrationForm.value);
  }

  userRegistration(){
    this.submitStatus = true;
    let url: string = `${environment.apiURL}${protectedResource.userRregistration.resourceURL}`;
    let data: any = {
      userName: this.userRegistrationForm.value.userName,
      userEmail: this.userRegistrationForm.value.userEmail,
      userPassword: this.userRegistrationForm.value.userPassword,
      userCountry: this.userRegistrationForm.value.userCountry?.name,
      userState: this.userRegistrationForm.value.userState?.name,
      userCity: this.userRegistrationForm.value.userCity?.name
    };
    this.userRegistrationSubscription = this.kriyababajiService.sendData(url, data).subscribe({
      next: (response) => {
        this.formType = 'V';
        this.snackBarService.showSnackBar(response.message, snackBarClass.Success);
        this.submitStatus = false;
        //console.log(response);
      },
      error: (err) => {
        this.snackBarService.showSnackBar(err && err.error && err.error.message ? err.error.message : err.message, snackBarClass.Error);
        this.submitStatus = false;
        console.log(err);
      }
    });
  }

  userVerfication(){
    this.submitStatus = true;
    let url: string = `${environment.apiURL}${protectedResource.userValidateRegistration.resourceURL}`;
    let optValue: string = `${this.userVerficationForm.value.opt1}${this.userVerficationForm.value.opt2}${this.userVerficationForm.value.opt3}${this.userVerficationForm.value.opt4}${this.userVerficationForm.value.opt5}${this.userVerficationForm.value.opt6}`;
    let data: any = {
      userEmail: this.userRegistrationForm.value.userEmail,
      //userVerificationCode: this.userVerficationForm.value.verificationCode ? +this.userVerficationForm.value.verificationCode : this.userVerficationForm.value.verificationCode
      userVerificationCode: Number(optValue)
    };
    this.userVerficationSubscription = this.kriyababajiService.patchData(url, data).subscribe({
      next: (response) => {
        this.snackBarService.showSnackBar(response.message, snackBarClass.Success);
        this.submitStatus = false;
        setTimeout(() => this.closePopup('Login'), 2000);
        //console.log(response);
      },
      error: (err) => {
        this.snackBarService.showSnackBar(err && err.error && err.error.message ? err.error.message : err.message, snackBarClass.Error);
        this.submitStatus = false;
        console.log(err);
      }
    });
  }

  receiveSelectValue(event: IreceiveSelect){
    this.userRegistrationForm.patchValue({[event.name]: event.value});
    if(event.name === 'userCountry'){
      this.userRegistrationForm.patchValue({userState: null, userCity: null});
      this.stateList = [];
      this.cityList = [];
      this.userRegistrationForm.value.userCountry ? this.getStateList() : null;
    }
    else if(event.name === 'userState'){
      this.userRegistrationForm.patchValue({userCity: null});
      this.cityList = [];
      this.userRegistrationForm.value.userCountry && this.userRegistrationForm.value.userState ? this.getCityList() : null;
    }
    //console.log(event);
  }

  //Get Country List
  getCountryList(){
    this.countryStatus = true;
    let url: string = `${environment.apiURL}${protectedResource.country.resourceURL}`;
    this.kriyababajiService.getData(url).subscribe({
      next: (response) => {
        response.value && response.value.length > 0 ? this.countryList = response.value : (this.countryList = [], this.snackBarService.showSnackBar(response.message, snackBarClass.Warning));
        this.countryStatus = false;
        //console.log(response);
      },
      error: (err) => {
        this.countryList = [];
        this.countryStatus = false;
        this.snackBarService.showSnackBar(err && err.error && err.error.message ? err.error.message : err.message, snackBarClass.Error);
        console.log(err);
      }
    });
  }

  //Get State List
  getStateList(){
    this.stateStatus = true;
    let url: string = `${environment.apiURL}${protectedResource.state.resourceURL}/${this.userRegistrationForm.value.userCountry?.isoCode}`;
    this.kriyababajiService.getData(url).subscribe({
      next: (response) => {
        response.value && response.value.length > 0 ? this.stateList = response.value : (this.stateList = [], this.snackBarService.showSnackBar(response.message, snackBarClass.Warning));
        this.stateStatus = false;
        //console.log(response);
      },
      error: (err) => {
        this.stateList = [];
        this.stateStatus = false;
        this.snackBarService.showSnackBar(err && err.error && err.error.message ? err.error.message : err.message, snackBarClass.Error);
        console.log(err);
      }
    });
  }

  //Get City List
  getCityList(){
    this.cityStatus = true;
    let url: string = `${environment.apiURL}${protectedResource.city.resourceURL}/${this.userRegistrationForm.value.userCountry?.isoCode}/${this.userRegistrationForm.value.userState?.isoCode}`;
    this.kriyababajiService.getData(url).subscribe({
      next: (response) => {
        response.value && response.value.length > 0 ? this.cityList = response.value : (this.cityList = [], this.snackBarService.showSnackBar(response.message, snackBarClass.Warning));
        this.cityStatus = false;
        //console.log(response);
      },
      error: (err) => {
        this.cityList = [];
        this.cityStatus = false;
        this.snackBarService.showSnackBar(err && err.error && err.error.message ? err.error.message : err.message, snackBarClass.Error);
        console.log(err);
      }
    });
  }

  closePopup(data?: string){
    this.dialogRef.close(data);
  }

  focusNextInput(event: any, order: number){
    if((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)){
      order !== 6 ? event.srcElement.nextElementSibling.focus() : null;
    }
    else if(event.keyCode === 8 || event.keyCode === 46){
      
    }
    //console.log(event);
  }
  pasteOptValue(event: ClipboardEvent){
    let getPasteValue: any = event.clipboardData?.getData('text');
    if(Number.isInteger(Number(getPasteValue)) && getPasteValue?.length === 6){
      getPasteValue = getPasteValue.split("");
      this.userVerficationForm.patchValue({
        opt1: getPasteValue[0],
        opt2: getPasteValue[1],
        opt3: getPasteValue[2],
        opt4: getPasteValue[3],
        opt5: getPasteValue[4],
        opt6: getPasteValue[5]
      });
    }
    else{
      setTimeout(() => this.userVerficationForm.reset(), 100);
      this.snackBarService.showSnackBar('Enter valid OPT', snackBarClass.Error);
    }
  }

  matchValidator(controlName: string, matchingControlName: string): ValidatorFn{
    return (abstractControl: AbstractControl) => {
      const control = abstractControl.get(controlName);
      const matchingControl = abstractControl.get(matchingControlName);
      if(matchingControl!.errors && !matchingControl!.errors?.['confirmedValidator']){
        return null;
      }
      if(control!.value !== matchingControl!.value) {
        const error = { confirmedValidator: 'Passwords do not match.' };
        matchingControl!.setErrors(error);
        return error;
      }
      else{
        matchingControl!.setErrors(null);
        return null;
      }
    }
  }

  ngOnDestroy(): void {
    this.userRegistrationSubscription ? this.userRegistrationSubscription.unsubscribe() : null;
    this.userVerficationSubscription ? this.userVerficationSubscription.unsubscribe() : null;
  }
}
