@if(eventList && eventList.length > 0){
    <section class="event">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="eventContent">
                        <div class="eventTitle">
                            <h2>News & Events</h2>
                        </div>
                        <swiper-container pagination="true" pagination-clickable="true" pagination-dynamic-bullets="true" autoplay-delay="6000" autoplay-disable-on-interaction="false">
                            @for(item of eventList; track item){
                                <swiper-slide>
                                    <div class="eventDescribtion">
                                        <h4>{{item.eventName}}</h4>
                                        <p>{{item.eventDescription}}</p>
                                    </div>
                                </swiper-slide>  
                            }
                        </swiper-container>
                        <div class="eventImage">
                            <img src="assets/images/img1.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}