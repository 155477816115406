@if(appInstallStatus){
    <div class="appInstall">
        <div class="image">
            <img src="assets/images/icon.png" alt="logo" />
        </div>
        <div class="content">
            <h4>Install Kriya Babaji Yoga Sangam App</h4>
            @if(platform.IOS){<p>Tap the <mat-icon fontIcon="ios_share"></mat-icon> Menu button and then <mat-icon fontIcon="add_box"></mat-icon> Add to Home screen button</p>}
            <div class="action">
                <button mat-button color="primary" (click)="appInstallStatus = false">Close</button>
                @if(!platform.IOS){<button mat-raised-button color="primary" (click)="installApp()">Install</button>}
            </div>
        </div>
    </div>
}