import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-app-install',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './app-install.component.html',
  styleUrl: './app-install.component.scss'
})
export class AppInstallComponent{
  appInstallStatus: boolean = true;
  private promptEvent: any;
  constructor(public platform: Platform){
    window.addEventListener('beforeinstallprompt', (event: any) => {
      event.preventDefault();
      this.promptEvent = event;
    });
  }

  installApp(){
    if(!this.platform.IOS){
      if(!this.promptEvent){
        return;
      }
      this.promptEvent.prompt();
    }
  }
}
