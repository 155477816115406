import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { SwPush } from "@angular/service-worker";
import { io } from "socket.io-client";
import { MenuComponent } from '../menu/menu.component';
import { LoginComponent } from '../login/login.component';
import { RegistrationComponent } from '../registration/registration.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { protectedResource } from '../../auth/protected-resource';
import { KriyababajiService } from '../../services/kriyababaji/kriyababaji.service';
import { SnackBarService, snackBarClass } from '../../services/snack-bar/snack-bar.service';
import { UserDetailService } from '../../services/user-detail/user-detail.service';
import { OnlineUserService } from '../../services/online-user/online-user.service';
import { IuserDetail, IonlineUserDetail } from '../../services/interface/interface.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatDialogModule, MatMenuModule, MenuComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, OnDestroy{
  getScrollPosition: number = 0;
  userDetail: IuserDetail | null = null;
  logoutSubscription: Subscription;
  refreshTokenSubscription: Subscription;
  socket = environment.production ? io({path: environment.socketURL}) : io(environment.socketURL);
  onlineUserDetail: IonlineUserDetail[] = [];
  userNotificationStatus: boolean = false;
  constructor(
    private swPush: SwPush,
    private router: Router,
    public dialog: MatDialog,
    private kriyababajiService: KriyababajiService,
    private snackBarService: SnackBarService,
    private userDetailService: UserDetailService,
    private onlineUserService: OnlineUserService
  ){}

  ngOnInit(): void {
    this.userDetailService.currentMessage.subscribe(data => {
      this.userDetail = data ? data : null;
      this.userDetail && !this.userNotificationStatus ? this.requestSubscription() : null;
      this.socket && this.socket.id ? this.socket.emit("userRegister", {socketID: this.socket.id, userDetail: this.userDetail}) : null;
    });
    this.checkUserDetail();
    this.getScrollPosition = document.documentElement.scrollTop;
    document.addEventListener('scroll', () => {
      this.getScrollPosition = document.documentElement.scrollTop;
    });
    this.socket.on("connect", () => {
      //console.log(this.socket.id);
      this.socket.emit("userRegister", {socketID: this.socket.id, userDetail: this.userDetail});
    });
    this.socket.on("onlineUser", (data) => {
      this.onlineUserDetail = data;
      this.onlineUserService.changeMessage(this.onlineUserDetail);
      //console.log(this.onlineUserDetail);
    });
    this.socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });
  }

  checkUserDetail(){
    this.userDetail = localStorage.getItem("userDetail") ? JSON.parse(localStorage.getItem("userDetail") as any) : null;
    this.userDetail ? (this.userDetailService.changeMessage(this.userDetail), this.refreshToken()) : null;
  }

  openLoginPopup(){
    const dialogRef = this.dialog.open(LoginComponent, {
      maxWidth: "460px",
      maxHeight: "320px",
      data: 'Hi',
      panelClass: "commonPopup",
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result === 'Register'){
        this.openRegistrationPopup();
      }
      else if(result === 'Forgot Password'){
        this.openForgotPasswordPopup();
      }
      //console.log(result, 'The dialog was closed');
    });
  }

  openRegistrationPopup(){
    const dialogRef = this.dialog.open(RegistrationComponent, {
      maxWidth: "700px",
      maxHeight: "400px",
      data: 'Hi',
      panelClass: "commonPopup",
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result === 'Login'){
        this.openLoginPopup();
      }
      //console.log(result, 'The dialog was closed');
    });
  }

  openForgotPasswordPopup(){
    const dialogRef = this.dialog.open(ForgotPasswordComponent, {
      width: "700px",
      height: "400px",
      data: 'Hi',
      panelClass: "commonPopup",
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result === 'Login'){
        this.openLoginPopup();
      }
      //console.log(result, 'The dialog was closed');
    });
  }

  refreshToken(){
    let url: string = `${environment.apiURL}${protectedResource.userRefreshToken.resourceURL}`;
    this.refreshTokenSubscription = this.kriyababajiService.getData(url).subscribe({
      next: (response) => {
        this.userDetail = response.value;
        this.userDetailService.changeMessage(this.userDetail);
        localStorage.setItem('userDetail', JSON.stringify(this.userDetail));
        //console.log(response);
      },
      error: (err) => {
        this.userDetailService.changeMessage(null);
        localStorage.clear();
        this.router.navigate(['/home']);
        this.snackBarService.showSnackBar(err && err.error && err.error.message ? err.error.message : err.message, snackBarClass.Error);
        //console.log(err);
      }
    });
  }

  setNavigation(){
    this.router.navigate(['/admin']);
  }

  requestSubscription = () => {
    if (!this.swPush.isEnabled) {
      console.log("Notification is not enabled.");
      return;
    }
    this.userNotificationStatus = true;
    this.swPush.requestSubscription({
      serverPublicKey: 'BDqruGUJzLtdSHMOTBhFHzTGzK8OPW2nnuyab7YOAxu_Rq8vizVve8okkxA7Qdb5nPg1I_mTDlbTGGoazBmlgXQ'
    }).then((data) => {
      this.userNotification(JSON.stringify(data));
      //console.log(JSON.stringify(data));
    }).catch((data) => console.log(data));
  };

  userNotification(notificationData: string){
    let url: string = `${environment.apiURL}${protectedResource.notification.resourceURL}`;
    let data: any = {
      notificationDetail: JSON.stringify(notificationData),
      notificationPlatform: (window.navigator as any).userAgentData.platform
    };
    this.kriyababajiService.sendData(url, data).subscribe({
      next: (response) => {
        //console.log(response);
      },
      error: (err) => {
        //console.log(err);
      }
    });
  }

  logout(type?: string){
    let url: string = `${environment.apiURL}${protectedResource.userLogout.resourceURL}`;
    this.logoutSubscription = this.kriyababajiService.getData(url).subscribe({
      next: (response) => {
        if(type){
          this.userDetailService.changeMessage(null);
          localStorage.clear();
          this.snackBarService.showSnackBar(response.message, snackBarClass.Success);
          this.router.navigate(['/home']);
        }
        //console.log(response);
      },
      error: (err) => {
        this.snackBarService.showSnackBar(err && err.error && err.error.message ? err.error.message : err.message, snackBarClass.Error);
        //console.log(err);
      }
    });
  }

  scrollTop(){
    document.documentElement.scrollTop = 0;
  }

  ngOnDestroy(): void {
    this.logout();
  }
}
