<div class="title" mat-dialog-title>
    <h4>Login</h4>
    <button type="button" mat-icon-button color="warn" (click)="closePopup()"><mat-icon fontIcon="close"></mat-icon></button>
</div>
<mat-dialog-content class="mat-typography">
    <div class="formSection">
        <form [formGroup]="loginForm" (ngSubmit)="submit()">
            <div class="row">
                <div class="col-md-12">
                    <div class="formField">
                        <mat-form-field appearance="outline">
                            <mat-label>Enter your Email</mat-label>
                            <input matInput formControlName="userEmail">
                            @if(f['userEmail'].errors && f['userEmail'].touched && f['userEmail'].errors['required']){<mat-error>Email is required</mat-error>}
                            @else if(f['userEmail'].errors && f['userEmail'].touched && f['userEmail'].errors['email']){<mat-error>Email is not valid</mat-error>}
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="formField">
                        <mat-form-field appearance="outline">
                            <mat-label>Enter your password</mat-label>
                            <input matInput [type]="passwordStatus ? 'password' : 'text'" formControlName="userPassword">
                            <button mat-icon-button matSuffix (click)="passwordStatus = !passwordStatus" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordStatus">
                              <mat-icon>{{passwordStatus ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                            @if(f['userPassword'].errors && f['userPassword'].touched && f['userPassword'].errors['required']){<mat-error>Password is required</mat-error>}
                            @else if(f['userPassword'].errors && f['userPassword'].touched && f['userPassword'].errors['minlength']){<mat-error>Enter minimum 6 character</mat-error>}
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="closePopup('Forgot Password')">Forgot Password?</button>
    <button mat-button color="primary" (click)="closePopup('Register')">Register</button>
    <button mat-flat-button color="primary" (click)="submit()">Login</button>
</mat-dialog-actions>