import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SelectComponent, IreceiveSelect } from '../select/select.component';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { protectedResource } from '../../auth/protected-resource';
import { KriyababajiService } from '../../services/kriyababaji/kriyababaji.service';
import { SnackBarService, snackBarClass } from '../../services/snack-bar/snack-bar.service';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, FormsModule, ReactiveFormsModule, MatDialogModule, MatButtonModule, MatIconModule, MatFormFieldModule, MatInputModule, MatProgressSpinnerModule, SelectComponent],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent implements OnInit, OnDestroy{
  formType: 'E' | 'V' = 'E';
  userForgotPasswordEmailForm = new FormGroup({
    userEmail: new FormControl<string | null>(null, [Validators.required, Validators.email])
  });
  userForgotPasswordVerificationForm = new FormGroup({
    userPassword: new FormControl<string | null>(null, [Validators.required, Validators.minLength(6)]),
    confirmPassword: new FormControl<string | null>(null, [Validators.required]),
    opt1: new FormControl<string | null>(null, [Validators.required, Validators.minLength(1), Validators.maxLength(1), Validators.pattern(/^[0-9]\d*$/)]),
    opt2: new FormControl<string | null>(null, [Validators.required, Validators.minLength(1), Validators.maxLength(1), Validators.pattern(/^[0-9]\d*$/)]),
    opt3: new FormControl<string | null>(null, [Validators.required, Validators.minLength(1), Validators.maxLength(1), Validators.pattern(/^[0-9]\d*$/)]),
    opt4: new FormControl<string | null>(null, [Validators.required, Validators.minLength(1), Validators.maxLength(1), Validators.pattern(/^[0-9]\d*$/)]),
    opt5: new FormControl<string | null>(null, [Validators.required, Validators.minLength(1), Validators.maxLength(1), Validators.pattern(/^[0-9]\d*$/)]),
    opt6: new FormControl<string | null>(null, [Validators.required, Validators.minLength(1), Validators.maxLength(1), Validators.pattern(/^[0-9]\d*$/)])
  },
  {
    validators: this.matchValidator('userPassword', 'confirmPassword')
  });
  userForgotPasswordEmailSubscription: Subscription;
  userForgotPasswordVerificationSubscription: Subscription;
  passwordStatus: boolean = true;
  confirmPasswordStatus: boolean = true;
  submitStatus: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private kriyababajiService: KriyababajiService,
    private snackBarService: SnackBarService
  ){}

  ngOnInit(): void {
    //console.log(this.data);
  }

  get e():{[key: string]: AbstractControl}{
    return this.userForgotPasswordEmailForm.controls;
  }
  get v():{[key: string]: AbstractControl}{
    return this.userForgotPasswordVerificationForm.controls;
  }

  submit(){
    this.formType === 'E' ? this.checkUserEmail() : this.changePassword();
  }

  checkUserEmail(){
    if(this.userForgotPasswordEmailForm.valid){
      this.submitStatus = true;
      let url: string = `${environment.apiURL}${protectedResource.userForgotPassworkUserCheck.resourceURL}`;
      this.userForgotPasswordEmailSubscription = this.kriyababajiService.patchData(url, this.userForgotPasswordEmailForm.value).subscribe({
        next: (response) => {
          this.formType = 'V';
          this.snackBarService.showSnackBar(response.message, snackBarClass.Success);
          this.submitStatus = false;
          //console.log(response);
        },
        error: (err) => {
          this.snackBarService.showSnackBar(err && err.error && err.error.message ? err.error.message : err.message, snackBarClass.Error);
          this.submitStatus = false;
          //console.log(err);
        }
      });
    }
  }

  changePassword(){
    if(this.userForgotPasswordVerificationForm.valid){
      this.submitStatus = true;
      let url: string = `${environment.apiURL}${protectedResource.userForgotPassword.resourceURL}`;
      let optValue: string = `${this.userForgotPasswordVerificationForm.value.opt1}${this.userForgotPasswordVerificationForm.value.opt2}${this.userForgotPasswordVerificationForm.value.opt3}${this.userForgotPasswordVerificationForm.value.opt4}${this.userForgotPasswordVerificationForm.value.opt5}${this.userForgotPasswordVerificationForm.value.opt6}`;
      let data: any = {
        userEmail: this.userForgotPasswordEmailForm.value.userEmail,
        userPassword: this.userForgotPasswordVerificationForm.value.userPassword,
        userVerificationCode: Number(optValue)
      };
      this.userForgotPasswordVerificationSubscription = this.kriyababajiService.patchData(url, data).subscribe({
        next: (response) => {
          this.snackBarService.showSnackBar(response.message, snackBarClass.Success);
          this.submitStatus = false;
          setTimeout(() => this.closePopup('Login'), 2000);
          //console.log(response);
        },
        error: (err) => {
          this.snackBarService.showSnackBar(err && err.error && err.error.message ? err.error.message : err.message, snackBarClass.Error);
          this.submitStatus = false;
          //console.log(err);
        }
      });
    }
  }

  closePopup(data?: string){
    this.dialogRef.close(data);
  }

  focusNextInput(event: any, order: number){
    if((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)){
      order !== 6 ? event.srcElement.nextElementSibling.focus() : null;
    }
    else if(event.keyCode === 8 || event.keyCode === 46){
      
    }
    //console.log(event);
  }
  pasteOptValue(event: ClipboardEvent){
    let getPasteValue: any = event.clipboardData?.getData('text');
    if(Number.isInteger(Number(getPasteValue)) && getPasteValue?.length === 6){
      getPasteValue = getPasteValue.split("");
      this.userForgotPasswordVerificationForm.patchValue({
        opt1: getPasteValue[0],
        opt2: getPasteValue[1],
        opt3: getPasteValue[2],
        opt4: getPasteValue[3],
        opt5: getPasteValue[4],
        opt6: getPasteValue[5]
      });
    }
    else{
      setTimeout(() => this.userForgotPasswordVerificationForm.reset(), 100);
      this.snackBarService.showSnackBar('Enter valid OPT', snackBarClass.Error);
    }
  }

  matchValidator(controlName: string, matchingControlName: string): ValidatorFn{
    return (abstractControl: AbstractControl) => {
      const control = abstractControl.get(controlName);
      const matchingControl = abstractControl.get(matchingControlName);
      if(matchingControl!.errors && !matchingControl!.errors?.['confirmedValidator']){
        return null;
      }
      if(control!.value !== matchingControl!.value) {
        const error = { confirmedValidator: 'Passwords do not match.' };
        matchingControl!.setErrors(error);
        return error;
      }
      else{
        matchingControl!.setErrors(null);
        return null;
      }
    }
  }

  ngOnDestroy(): void {
    this.userForgotPasswordEmailSubscription ? this.userForgotPasswordEmailSubscription.unsubscribe() : null;
    this.userForgotPasswordVerificationSubscription ? this.userForgotPasswordVerificationSubscription.unsubscribe() : null;
  }
}
