import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { EventComponent } from './component/event/event.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button'
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { register } from 'swiper/element/bundle';
import { AppUpdateService } from './services/app-update/app-update.service';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent, FooterComponent, EventComponent, MatIconModule, MatButtonModule, MatBottomSheetModule, MatSnackBarModule],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AppUpdateService]
})
export class AppComponent implements OnInit{
  title = 'KriyaBabaji.APP';

  constructor(private appUpdateService: AppUpdateService){}

  ngOnInit(): void {
    register();
  }
}
