<div class="title" mat-dialog-title>
    <h4>Forgot Password</h4>
    <button type="button" mat-icon-button color="warn" (click)="closePopup()"><mat-icon fontIcon="close"></mat-icon></button>
</div>
<mat-dialog-content class="mat-typography">
    <div class="formSection">
        @if(formType === 'E'){
            <form [formGroup]="userForgotPasswordEmailForm" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-md-6">
                        <div class="formField">
                            <mat-form-field appearance="outline">
                                <mat-label>Enter your Email</mat-label>
                                <input matInput formControlName="userEmail">
                                @if(e['userEmail'].errors && e['userEmail'].touched && e['userEmail'].errors['required']){<mat-error>Email is required</mat-error>}
                                @else if(e['userEmail'].errors && e['userEmail'].touched && e['userEmail'].errors['email']){<mat-error>Email is not valid</mat-error>}
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>
        }
        @else if(formType === 'V'){
            <form [formGroup]="userForgotPasswordVerificationForm" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-md-12">
                        <div class="verificationSeaction">
                            <h3>Verify your email address</h3>
                            <p>We have send you an verification code on this email address <b>{{userForgotPasswordEmailForm.value.userEmail}}</b></p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="formField">
                            <mat-form-field appearance="outline">
                                <mat-label>Enter your password</mat-label>
                                <input matInput [type]="passwordStatus ? 'password' : 'text'" formControlName="userPassword">
                                <button type="button" mat-icon-button matSuffix (click)="passwordStatus = !passwordStatus" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordStatus">
                                  <mat-icon>{{passwordStatus ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                @if(v['userPassword'].errors && v['userPassword'].touched  && v['userPassword'].errors['required']){<mat-error>Password is required</mat-error>}
                                @else if(v['userPassword'].errors && v['userPassword'].touched  && v['userPassword'].errors['minlength']){<mat-error>Enter minimum 6 character</mat-error>}
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="formField">
                            <mat-form-field appearance="outline">
                                <mat-label>Enter your confirm password</mat-label>
                                <input matInput [type]="confirmPasswordStatus ? 'password' : 'text'" formControlName="confirmPassword">
                                <button type="button" mat-icon-button matSuffix (click)="confirmPasswordStatus = !confirmPasswordStatus" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="confirmPasswordStatus">
                                  <mat-icon>{{confirmPasswordStatus ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                @if(v['confirmPassword'].errors && v['confirmPassword'].touched && v['confirmPassword'].errors['required']){<mat-error>Confirm password is required</mat-error>}
                                @else if(v['confirmPassword'].errors && v['confirmPassword'].touched && v['confirmPassword'].errors['confirmedValidator']){<mat-error>Passwords do not match</mat-error>}
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="verificationSeaction">
                            <p>Enter OPT</p>
                        </div>
                        <div class="optVerificaionField">
                            <input type="text" matInput formControlName="opt1" maxlength="1" (keyup)="focusNextInput($event, 1)" (paste)="pasteOptValue($event)" />
                            <input type="text" matInput formControlName="opt2" maxlength="1" (keyup)="focusNextInput($event, 2)" (paste)="pasteOptValue($event)" />
                            <input type="text" matInput formControlName="opt3" maxlength="1" (keyup)="focusNextInput($event, 3)" (paste)="pasteOptValue($event)" />
                            <input type="text" matInput formControlName="opt4" maxlength="1" (keyup)="focusNextInput($event, 4)" (paste)="pasteOptValue($event)" />
                            <input type="text" matInput formControlName="opt5" maxlength="1" (keyup)="focusNextInput($event, 5)" (paste)="pasteOptValue($event)" />
                            <input type="text" matInput formControlName="opt6" maxlength="1" (keyup)="focusNextInput($event, 6)" (paste)="pasteOptValue($event)" />
                        </div>
                    </div>
                </div>
            </form>
        }
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button type="button" mat-button color="primary" (click)="closePopup('Login')">Login</button>
    <button type="button" mat-flat-button color="primary" (click)="submit()" [disabled]="submitStatus">
        @if(submitStatus){<mat-spinner class="buttonLoader color1" [diameter]="17" [strokeWidth]="2"></mat-spinner>}
        {{ formType === 'E' ? 'Verify Email' : 'Change Password' }}
    </button>
</mat-dialog-actions>