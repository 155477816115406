import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { UserDetailService } from '../../../services/user-detail/user-detail.service';
import { SnackBarService, snackBarClass } from '../../../services/snack-bar/snack-bar.service';

export const adminGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const snackBar = inject(SnackBarService);
  let userDetail: any = null;
  inject(UserDetailService).currentMessage.subscribe(data => userDetail = data);
  return userDetail ? userDetail.userRole.includes('Admin') ? true : (snackBar.showSnackBar('You not have access', snackBarClass.Error), router.navigate(['/']), false) : (snackBar.showSnackBar('You not have access', snackBarClass.Error), router.navigate(['/']), false);
};
