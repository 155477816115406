import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { UpdateComponent } from '../../component/update/update.component';

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {

  constructor(updates: SwUpdate, private bottomSheet: MatBottomSheet){
    updates.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          const bottomSheetRef = this.bottomSheet.open(UpdateComponent, {
            data: 'New app version available click here to update',
            disableClose: true
          });
      
          bottomSheetRef.afterDismissed().subscribe(() => {
            location.reload();
          });
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });
  }
}
