import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { io } from "socket.io-client";
import { environment } from '../../../environments/environment';
import { protectedResource } from '../../auth/protected-resource';
import { KriyababajiService } from '../../services/kriyababaji/kriyababaji.service';
import { SnackBarService, snackBarClass } from '../../services/snack-bar/snack-bar.service';
import { IeventList } from '../../services/interface/interface.service';

@Component({
  selector: 'app-event',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule],
  templateUrl: './event.component.html',
  styleUrl: './event.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EventComponent implements OnInit{
  eventList: IeventList[] = [];
  socket = environment.production ? io({path: environment.socketURL}) : io(environment.socketURL);
  constructor(
    private kriyababajiService: KriyababajiService,
    private snackBarService: SnackBarService
  ){}
  
  ngOnInit(): void {
    this.getEventList();
    this.socket.on("eventUpdate", (data) => {
      this.getEventList();
    });
  }

  getEventList(){
    this.eventList = [];
    let url: string = `${environment.apiURL}${protectedResource.event.resourceURL}`;
    this.kriyababajiService.getData(url).subscribe({
      next: (response) => {
        this.eventList = response.value && response.value.length > 0 ? response.value : [];
        //console.log(response);
      },
      error: (err) => {
        this.eventList = [];
        this.snackBarService.showSnackBar(err && err.error && err.error.message ? err.error.message : err.message, snackBarClass.Error);
        //console.log(err);
      }
    });
  }
}
